<template>

    <b-modal id="campos-Personalizados" 
             scrollable 
             ref="camposPersonalizados" 
             centered title="Campos Personalizados" 
             class="titleModal" 
             size="xl" 
             @hide="limparFormulario">

        <center  v-if="buscarComplementoTimer">
            <i class="fas fa-circle-notch fa-spin carregandoOrc mx-2" 
               small >
            </i>
            <label class="carregandoOrc"> Carregando ...</label>
        </center>
        <b-container v-else>

                <!--CAMPOS  DINAMICOS =========================================== -->
            <div v-for="(campo, index) in campos">
                <b-row :key="index"
                       align-v="center">

                    <b-col cols="6"
                           class="data-field">
                        <label>Nome do campo</label><br />
                        <input type="text"
                               v-model="campo.NomeCampo" />
                    </b-col>

                    <b-col cols="5" class="data-field">
                        <label>Tipo de campo</label>

                        <select class="tipoDeCampo" v-model="campo.TipoCampo">

                            <option value="0">Caixa de Texto</option>
                            <option value="3">Lista suspensa</option>

                        </select>
                    </b-col>
                    <b-col cols="1">
                        <i class="fas fa-trash-alt mt-2 mr-3 float-right"
                           @click="removerItem(index)"
                           style="cursor:pointer">
                        </i>
                    </b-col>
                </b-row>

                <!--OPÇÕES DA LISTA SUSPENSA ====================================-->
                <b-row v-if="campo.TipoCampo == 3" align-v="center">

                    <b-col cols="11" class="data-field">

                        <label>{{campo.NomeCampo}}: opções da lista suspensa</label><br />
                        <div class="caixa-opcoes">

                            <div v-for="(campo, ordem) in campo.ClientesComplementoOpcao">
                                <b-row :key="ordem"
                                       align-v="center">
                                    <b-col cols="11"
                                           class="data-field">
                                        <label>Nome da Opção</label>
                                        <input type="text"
                                               v-model="campo.NomeOpcao" />
                                    </b-col>
                                    <b-col cols="1">
                                        <i class="fas fa-trash-alt mt-2 mr-3 float-right"
                                           @click="removerOpcao(ordem,index)"
                                           style="cursor:pointer">
                                        </i>
                                    </b-col>
                                </b-row>

                            </div>

                            <b-row>
                                <b-col cols="12" 
                                        class="adicionar-opcao-btn">

                                    <button class="addButtom"
                                            id="add-option-btn"
                                            @click="adicionarOpcao(index)">
                                        + Adicionar opção
                                    </button>
                                </b-col>
                            </b-row>

                        </div>

                    </b-col>
                </b-row>
            </div>
                <!--BOTAO ADICIONAR NOVOS CAMPOS ================================-->
                <b-row class="d-flex align-items-center justify-content-center">

                    <b-button class="addButtom rounded-0" 
                              @click="adicionarCampo"
                              id="add-campo-Btn">+ Novo campo</b-button>

                </b-row>

        </b-container>



        <!--BOTOES FOOTER MODAL =================================================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancelarFormulario" class="rounded-0">Cancelar</b-button>

            <b-button id="botaoSalvar" variant="success" @click="salvarCampos" :disabled="isSending" class="rounded-0">
                <span v-if="isSending">
                    <b-spinner small class="mr-1"></b-spinner>
                    Salvando
                </span>
                <span v-else>
                    Salvar
                </span>
            </b-button>

        </template>
    </b-modal>

</template>



<script>
    import axios from "axios";

    export default {

        name:'camposPersonalizadosModal',

        data() {
            return {
                buscarComplementoTimer: false,
                isSending: false,

                campos: [],

                lixosIds: [],

                promiseEdita: null,
                promiseDeletaOp: null,
                promiseSalva: null,
                promiseDeleta:null,
                lixosIdsOpcao: [],
                arrayLista:''
            };
        },

        watch:{


        },
        
        methods: {
            iniciarModal() {
                this.buscarComplementoTimer = true,
                axios.get(`api/v2/clientes/BuscarConfiguracoes`).then(response => {
                    response.data.forEach(campo => {
                        this.campos.push({Id: campo.Id, NomeCampo: campo.NomeCampo, TipoCampo: campo.TipoCampo, ClientesComplementoOpcao: campo.ClientesComplementoOpcao })
                    });
                    this.buscarComplementoTimer = false;
                    if(this.campos.length == 0){
                        this.campos.push({ NomeCampo: '', TipoCampo: '', ClientesComplementoOpcao: [] });
                    }
                })
                this.$bvModal.show("campos-Personalizados");
                const promise1 = new Promise(resolve => this.promiseSalva = resolve);
                const promise2 = new Promise(resolve => this.promiseEdita = resolve);
                const promise3 = new Promise(resolve => this.promiseDeleta = resolve);
                const promise4 = new Promise(resolve => this.promiseDeletaOp = resolve);
                return Promise.all([promise1, promise2, promise3,promise4]).then(() => {
                    this.isSending = false;
                });
            },

            adicionarCampo() {
                this.campos.push({NomeCampo: '', TipoCampo: '',ClientesComplementoOpcao:[]})
            },

            salvarCampos() {
                this.isSending = true;
                var itensSemId = this.campos.filter(item => !item.Id && item.NomeCampo != "" && item.TipoCampo != "").map(item => {
                    return item;
                });

                let dadosSalvar = {
                    Configuracao : itensSemId,
                    EmpresaId : null
                }

                let itensComId = this.campos.filter(item => item.Id).map(item => {
                    if(item.TipoCampo != 3){
                        item.ClientesComplementoOpcao = null;
                    }
                    return item
                });

                let dadosEditar = {
                    Configuracao : itensComId,
                    EmpresaId : null
                }

                axios.post(`api/v2/clientes/SalvarConfiguracoes`,dadosSalvar).then(() => {
                    this.promiseSalva(true);
                }).catch(error => {
                    console.log(error);
                    this.isSending = false;
                    this.$bvToast.toast(`Houve um erro ao tentar salvar os campos personalizados .`, {
                        title: "Ops, algo deu errado...",
                        toaster: "b-toaster-top-right",
                        variant: "danger",
                        solid: true,
                        autoHideDelay: 5000
                    });
                })

                axios.put(`api/v2/clientes/EditarComplemento`,dadosEditar).then(() => {  
                    this.promiseEdita(true);
                }).catch(error => {
                    console.log(error);
                    this.isSending = false;
                    this.$bvToast.toast(`Houve um erro ao tentar salvar os campos personalizados .`, {
                        title: "Ops, algo deu errado...",
                        toaster: "b-toaster-top-right",
                        variant: "danger",
                        solid: true,
                        autoHideDelay: 5000
                    });
                })

                if(this.lixosIds.length > 0){
                    let ids = Object.assign({}, ...this.lixosIds.map((item, index) => ({ [`ids[${index}]`]: item })));
                    axios.delete('api/v2/clientes/DeletarConfig', {
                        params: ids
                    }).then(() => {
                        this.promiseDeleta(true);
                    }).catch(error =>{
                        console.log(error);
                        this.isSending = false;
                        this.$bvToast.toast(`Houve um erro ao tentar salvar os campos personalizados .`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
                else{
                    this.promiseDeleta(true);
                }
                
                if(this.lixosIdsOpcao.length > 0){
                    let ids = Object.assign({}, ...this.lixosIdsOpcao.map((item, index) => ({ [`ids[${index}]`]: item })));
                    axios.delete('api/v2/clientes/DeletarOpcao', {
                        params: ids
                    }).then(() => {
                        this.promiseDeletaOp(true);
                    }).catch(error =>{
                        console.log(error);
                        this.isSending = false;
                        this.$bvToast.toast(`Houve um erro ao tentar salvar os campos personalizados .`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
                else{
                    this.promiseDeletaOp(true);
                }

            },

            cancelarFormulario() {

                this.$bvModal.msgBoxConfirm("As alterações não foram salvas, fechar mesmo assim?", {
                    title: "Atenção",
                    size: "md",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Fechar",
                    cancelTitle: "Cancelar",
                    centered: true,
                }).then(confirm => {
                    if (confirm) {
                        this.fecharFormulario();
                    }
                });
            },
            fecharFormulario(){
                this.$refs.camposPersonalizados.hide();
                this.limparFormulario();
            },
            limparFormulario() {
                this.campos = [];
            },

            removerItem(index) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o Campo?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        if (this.campos[index].Id) {
                            this.lixosIds.push(this.campos[index].Id);
                        }
                        if (this.campos.length >= 1) {
                            this.campos.splice(index, 1);
                        }
                    }
                })
            },
            adicionarOpcao(index){
                this.campos[index].ClientesComplementoOpcao.push({NomeOpcao: '',TipoCampo:this.campos[index].TipoCampo});
            },
            removerOpcao(ordem,index){
                this.$bvModal.msgBoxConfirm("Deseja excluir a opção?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        if (this.campos[index].ClientesComplementoOpcao[ordem].Id) {
                            this.lixosIdsOpcao.push(this.campos[index].ClientesComplementoOpcao[ordem].Id);
                        }
                        if (this.campos.length >= 1) {
                            this.campos[index].ClientesComplementoOpcao.splice(ordem, 1)
                        }
                    }
                })
            }

        }
    }



</script>

<style scoped>
    .caixa-opcoes {
        min-height: 5vh;
        padding: 2%;
        border: 1px solid #737373;
        padding-left: 1.5vw;
    }
    .titleModal {

        color: #737373;
    }

    #campos-Personalizados {
        min-width: 1200px;
    }

    #campos-Personalizados input[type=text], #campos-Personalizados textarea {
        opacity: 1;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
        width: 100%;
    }    
    
    #campos-Personalizados input[type=text]:focus, #campos-Personalizados textarea:focus{
       
        background-color: #F5F2F2;
    }

    .data-field {
        color: #737373;

    }

    textarea {
        resize: none;
    }

    #campos-Personalizados input[type=checkbox] {
        display: none;
    }

    .opcoesCheckbox label {
        font-size: 12px;
        cursor: pointer;
        padding: 5px;
        width: 100%;
        opacity: 0.5;
        background-color: #F5F2F2;
        margin: 0;
    }

    .opcoesCheckbox {
        border: 1px #737373 solid;
    }
     
    #campos-Personalizados input[type=checkbox]:hover + label {
        opacity: 1;
    }

    #campos-Personalizados input[type=checkbox]:checked + label {
        background-color: #E8E8E8;
        opacity: 1;
    }

    .tipoDeCampo {
        background-color: #F5F2F2;
        border: 1px #737373 solid;
    }

    .adicionar-opcao-btn {
        margin-top: 2%;
        margin-bottom: 2%;
        text-align: initial;
    }

    #add-option-btn{
        cursor:pointer;
        padding-top:0.7%;
        padding-bottom:0.7%
    }

    #add-campo-Btn {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .addButtom {
        padding-left: 40px;
        padding-right: 40px;
        background-color: transparent;
        border: 1px #737373 solid;
        color: #737373;
    }


</style>