<template>

    <b-modal id="Importar-Dados"
             scrollable
             centered
             ref="importarDados"
             class="titleModal"
             size="xl"
             @hide="limparDados()">

        <template #modal-title>
            <i class="fas fa-file-excel" id="title-icon" />
            Importar Dados
        </template>

        <b-container>

            <div class="selection-excel-file-block">

                <b-row>

                    <!--SELETOR DE ARQUIVO EXEL ======================================--->
                    <b-col cols="4">
                        <h6 class="text-truncate">
                            Pasta de trabalho
                        </h6>

                        <label for="excel-file-picker"
                               class="text-truncate"
                               :title="file?.fileName">

                            <i class="fas fa-file-excel"></i>
                            {{file?.fileName ?? "Nenhum arquivo selecionado..."}}
                        </label>

                        <input type="file"
                               ref="excel-file"
                               id="excel-file-picker"
                               accept=".xls, .xlsx"
                               @change="pickExcel" />
                    </b-col>


                    <!--SELETOR DE PLANILHA =========================================--->
                    <b-col cols="4">
                        <h6 class="text-truncate">Planilha</h6>
                        <select class="w-100"
                                v-model="sheet"
                                :disabled="!file">

                            <option v-for="sheet in (file?.sheets ?? [])"
                                    :value="sheet">
                                {{sheet?.name}}
                            </option>
                        </select>
                    </b-col>


                    <!--SELETOR DE CABEÇALHO =========================================-->
                    <b-col cols="4">
                        <h6 class="text-truncate">
                            Cabeçalho
                        </h6>

                        <select class="w-100"
                                v-model="(sheet ?? {}).hasHeader"
                                :disabled="!file">
                            <option :value="true">Presente</option>
                            <option :value="false">Ausente</option>
                        </select>
                    </b-col>
                </b-row>


                <!--PREVENÇÕES DE CARREGAMENTO E PROCESSAMENTO DO ARQUIVO ============-->
                <b-row v-if="isFileLoading">
                    <b-col class="mt-3 text-center text-secondary">
                        <b-spinner variant="secondary"
                                   small class="mr-1">
                        </b-spinner>
                        Carregando...
                    </b-col>
                </b-row>

                <b-row v-if="!isFileLoading && file && fields.length == 0">
                    <b-col class="mt-3">
                        <h6 class="text-center">Não há dados disponíveis na planilha selecionada.</h6>
                    </b-col>
                </b-row>

            </div>


            <!--TABELA DO ARQUIVO EXEL ===============================================-->

            <b-table ref="clientes-selector"
                     v-if="file"
                     :fields="fields"
                     :items="items"
                     select-mode="multi"
                     @row-selected="items => rowsSelected = items.map(item => item.Id)"
                     selected-variant="primary"
                     responsive
                     sticky-header
                     no-border-collapse
                     striped
                     class="data-viewer
                     app-scroll-custom mt-3">

                <template #head(select)="scope">
                    <input type="checkbox"
                           :checked="items.length == rowsSelected.length" @change="selectAllRows($event.target.checked)" />
                </template>


                <template #head(index)="scope">
                    #
                </template>

                <template #head()="scope">
                    <select v-if="file"
                            v-model="headerMap[scope.field.key]"
                            :class="`header-selector w-100 p-0 border-0 font-weight-bold${headerMap[scope.field.key] ? '' : ' undefined-column'}`">

                        <option :value="null">{{scope.field.label}}</option>

                        <optgroup label="Dado do Cliente">

                            <option v-for="coluna in colunasCliente"
                                    v-if="!Object.keys(headerMap).filter(item => item != scope.field.key).map(item => headerMap[item]).includes(coluna.value)"
                                    :value="coluna.value"
                                    :class="coluna.mandatory ? 'text-danger font-weight-bold' : ''">
                                {{coluna.label}}
                            </option>
                        </optgroup>
                    </select>


                    <div v-else class="text-nowrap">{{scope.label}}</div>
                </template>


                <template #cell(select)="{ index, rowSelected }">
                    <input type="checkbox"
                           :checked="rowSelected"
                           @change="changeRowSelection(index, $event.target.checked)" />
                </template>


                <template #cell(index)="data">
                    {{data.index + pageLimit * (selectedPage - 1) + 1}}
                </template>


                <template #cell()="data">
                    {{data.value}}
                </template>
            </b-table>
            <span v-if="file && !colunaSelecionada"
                  class="text-info-table">
                *Selecione pelo menos uma das colunas: "CNPJ/CPF", "Telefone" ou "E-mail".
            </span>

            <!--PAGINAÇÃO DA TABELA ==================================================-->

            <div v-if="pagesCount > 1"
                 class="data-viewer-pagination
                 text-center
                 mt-4">
                <b-button variant="light"
                          class="rounded-0"
                          :disabled="!selectedPage || selectedPage < 2"
                          @click="selectedPage = 1">
                    <i class="fas fa-angle-double-left"></i>
                </b-button>


                <b-button variant="light"
                          class="rounded-0"
                          :disabled="!selectedPage || selectedPage < 2"
                          @click="selectedPage -= 1">
                    <i class="fas fa-angle-left"></i>
                </b-button>


                <b-button v-for="page in pages"
                          :key="`page-${page}`"
                          :variant="page == selectedPage ? 'dark' : 'light'"
                          :disabled="page == selectedPage"
                          class="rounded-0"
                          @click="selectedPage = page">
                    {{page}}
                </b-button>


                <b-button variant="light"
                          class="rounded-0"
                          :disabled="!selectedPage || selectedPage > pagesCount - 1"
                          @click="selectedPage += 1">
                    <i class="fas fa-angle-right"></i>
                </b-button>


                <b-button variant="light"
                          class="rounded-0"
                          :disabled="!selectedPage || selectedPage > pagesCount - 1"
                          @click="selectedPage = pagesCount">
                    <i class="fas fa-angle-double-right"></i>
                </b-button>
            </div>

        </b-container>


        <!--BOTOES FOOTER MODAL =================================================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light"
                      @click="limparDados()"
                      class="rounded-0">
                Cancelar
            </b-button>

            <b-button variant="success"
                      class="rounded-0"
                      @click="ImportarClientes(items)"
                      :disabled="!isReady || isSending || !colunaRelacionada || !colunaSelecionada">
                      <span v-if="isSending">
                            <b-spinner small class="mr-1"></b-spinner>
                                Importando...
                      </span>
                <span v-else>
                    Confirmar
                </span>
            </b-button>
        </template>
    </b-modal>

</template>

<script>

    import ExcelFile from "@/assets/js/readers/excel.js"
import axios from "axios";

    export default {
        name: 'importarModal',

        props: {
            mandatoryFields: {
                type: Array,
                default() {
                    return [];
                }
            },
        },

        data() {
            return {
                resolve: null,
                colunasCliente: [

                    {
                        value: "Nome",
                        label: "Nome",
                        alias: "Nome,nome,nomecontato,contato,nomedecontato"
                    },

                    {
                        value: "Cnpj/Cpf",
                        label: "CNPJ/CPF",
                        alias: "cnpjcpf,cpf,cnpj,cpfcnpj"
                    },

                    {
                        value: "Telefone",
                        label: "Telefone",
                        alias: "telefone,fone,celular,whatsapp,numerotelefone,numerodetelefone"
                    },

                    {
                        value: "Email",
                        label: "Email",
                        alias: "email,enderecodeemail"
                    },
                    {
                        value: "Endereco",
                        label: "Endereço",
                        alias: "endereco,logradouro,rua"
                    },
                    {
                        value: "UF",
                        label: "Estado",
                        alias: "estado,Uf,uf"
                    },

                    {
                        value: "Municipio",
                        label: "Cidade",
                        alias: "cidade,municipio,naturalidade"
                    },

                ].map(item => ({ ...item, mandatory: this.mandatoryFields.includes(item.value) })),

                methods: [

                    {
                        name: "arquivo-excel",
                        label: "de uma pasta de trabalho do Excel (.xls, .xlsx)",
                        icon: "fa-file-excel",
                        color: "#1f7349"
                    },
                    {
                        name: "selecao-manual",
                        label: "de uma seleção manual da base de clientes",
                        icon: "fa-user-friends",
                        color: "#333",
                        isLoading: false
                    }
                ],

                colunaRelacionada: false,
                perfis: null,
                perfil: null,
                file: null,
                sheet: null,
                headerMap: null,
                resolve: null,
                rows: null,
                enviou: false,
                rowsSelected: [],
                isSending: false,
                noContent: false,
                isFileLoading: false,
                pageLimit: 100,
                selectedPage: 1,
            }
        },

        computed: {

            colunaSelecionada() {
                if(this.headerMap){

                    // let teste = this.sheet.header.map((key, index) => ({ hasRelationship: this.headerMap[String.fromCharCode(index + 65)] != null, value: this.headerMap[String.fromCharCode(index + 65)] ?? `:${key}` }))

                    return Object.values(this.headerMap).some(column => {
                        return ["Cnpj/Cpf", "Email", "Telefone"].includes(column);
                    });
                }
            },

            fields() {

                if (!this.sheet) return [];
                return this.sheet.header.length > 0 ? [{ key: "index", stickyColumn: true, variant: "success" }, ...this.sheet.header.map((field, index) => ({ key: String.fromCharCode(index + 65), label: field }))] : [];
            },

            items() {
                this.noContent = false;
                if (!this.sheet) return [];
                let rangeOut = this.selectedPage * this.pageLimit,
                    rangeIn = rangeOut - this.pageLimit;
                return this.sheet.rows.slice(rangeIn, rangeOut).map((item, row) => {
                    return Object.assign({}, ...this.sheet.header.map((value, index) => ({ [String.fromCharCode(index + 65)]: item[index] })), this.invalidRows.includes(row + 100 * (this.selectedPage - 1)) ? { _rowVariant: "danger" } : null);
                });
            },

            invalidRows() {
                if (!this.sheet) return [];
                let mandatoryFieldsMap = this.mandatoryFields.map(field => Object.values(this.headerMap).indexOf(field)).filter(item => item >= 0);
                return this.sheet.rows.map((row, index) => ([index, row])).filter(item => mandatoryFieldsMap.length > 0 && mandatoryFieldsMap.filter(field => item[1][field]?.toString().trim().length > 0).length == 0).map(item => item[0]);
            },

            isReady() {
                if (this.rows && this.rowsSelected.length === 0) return false;
                if (this.sheet && this.mandatoryFields.filter(item => {
                    return Object.values(this.headerMap).filter(column => column === item).length === 0;
                }).length > 0) return false;
                if (this.sheet && this.invalidRows.length === this.sheet.rows.length) return false;
                if (this.items.length > 0 && this.colunaRelacionada && this.colunaSelecionada) return true;
                return false;
            },

            pagesCount() {
                if (!this.sheet) return [];
                return Math.ceil(this.sheet.rows.length / this.pageLimit);
            },

            pages() {
                if (!this.sheet) return [];
                let firstItem = this.pagesCount - this.selectedPage >= 4 ? this.selectedPage - 4 : this.pagesCount - 8;
                if (firstItem < 1) firstItem = 1;
                return new Array(9).fill().map((page, index) => {
                    page = firstItem + index;
                    return page > this.pagesCount ? null : page;
                }).filter(page => page != null);
            }
        },

        watch: {
            headerMap: {
                handler(newHeaderMap) {
                    if(this.headerMap){
                        this.colunaRelacionada = Object.values(newHeaderMap).some(coluna => coluna !== null);
                    }
                },
                deep: true,
            },

            "sheet.header": {
                handler() {
                    if (!this.sheet) {
                        this.selectedPage = null;
                        return;
                    }
                    this.selectedPage = 1;
                    let usedValues = [];
                    this.headerMap = Object.assign({}, ...this.sheet.header.map((column, index) => {
                        let value = this.getColunaCliente(column).value ?? null;
                        if (value) {
                            if (usedValues.includes(value)) value = null;
                            else usedValues.push(value);
                        }
                        return { [String.fromCharCode(index + 65)]: value };
                    }));
                }
            },
        },
        created(){
            axios.get("api/v2/clientes/BuscarConfiguracoes").then(res => {
                this.colunasCliente = [
                    ...this.colunasCliente,
                    ...res.data.map(comp => ({
                        key: comp.Id,
                        label: comp.NomeCampo,
                        value: comp.Id,
                        type: comp.TipoCampo,
                        mandatory: false
                    }))
                ];
            });
        },
        methods: {

            iniciarModal() {
                this.$bvModal.show("Importar-Dados");

                return new Promise(resolve => this.enviou = resolve); 
            },

            limparDados() {
                this.$bvModal.hide("Importar-Dados");
                //this.colunasCliente = [];
                this.perfis = null;
                this.perfil = null;
                this.file = null;
                this.sheet = null;
                this.headerMap = null;
                this.resolve = null;
                this.rows = null;
                this.rowsSelected = [];
                this.noContent = false;
                this.isFileLoading = false;
            },

            async pickExcel() {
                this.file = null;
                this.sheet = null;
                let file = this.$refs["excel-file"].files[0];
                if (!file) return;
                if (!file.name.split(".").slice(-1)[0].startsWith("xls")) {
                    this.$bvModal.msgBoxOk(`O arquivo selecionado é inválido. Por favor, selecione um arquivo Excel (.xls ou .xlsx).`, {
                        title: "Seleção inválida...",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Entendi"
                    }).then(() => {
                        this.$refs["excel-file"].value = null;
                    });
                    return;
                }
                this.isFileLoading = true;
                this.file = new ExcelFile(file);
                this.file.read().then(() => {
                    this.sheet = (this.file?.sheets ?? [])[0];
                    this.isFileLoading = false;
                });
                return;
            },

            getColunaCliente(value) {
                if (value == null) return {};
                if (typeof value == "string") value = value.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/[^\p{L}\p{N}]+/gu, "").toLowerCase();
                return this.colunasCliente.filter(coluna => coluna.alias?.split(",").includes(value.toString()))[0] ?? {};
            },

            changeRowSelection(row, selected) {
                this.$refs["clientes-selector"][selected ? "selectRow" : "unselectRow"](row);
            },

            selectAllRows(select = true) {
                this.$refs["clientes-selector"][select ? "selectAllRows" : "clearSelected"]();
            },
            ImportarClientes(items){
                this.isSending = true;
                let headers = this.sheet.header.map((key, index) => ({ hasRelationship: this.headerMap[String.fromCharCode(index + 65)] != null, value: this.headerMap[String.fromCharCode(index + 65)] ?? `:${key}` }));
                    /*this.resolve({
                        source: "excel-file",
                        fields: headers.map(item => ({ field: item.value, label: item.hasRelationship ? this.getColunaCliente(item.value).label : item.value.slice(1) })),
                        rows: this.sheet.rows.filter((row, index) => !this.invalidRows.includes(index)).map(item => Object.assign({}, ...headers.map((field, index) => ({ [field.value]: item[index]?.toString() ?? null }))))
                    });*/
                var dataClients = []
                this.sheet.rows.map(row => {
                    var clients = {};
                    row.forEach((col, index) => {
                        clients[headers[index]?.key ?? headers[index]?.value.toString()] = col;
                    });
                    dataClients.push(clients);
                });

                axios.post("api/v2/clientes/importarClientes",dataClients).then(() => {
                    this.enviou(true);
                    this.limparDados()
                    this.isSending = false;

                    this.$bvToast.toast('Importação em andamento.', {
                        title: `Sucesso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    })
                }).catch(error => {
                    console.error(error);
                })
            }
        }

    }
        

</script>

<style scoped>

    input {
        border: 1px var(--cinza-4) solid;
        background-color: var(--cinza-2);
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        transition: all ease-in-out .3s;
    }
        input:focus, select:focus {
            filter: brightness(1.03);
        }

    select {
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        transition: all ease-in-out .3s;
    }

    .header-selector{
        min-width: 50px;
    }

    #title-icon {
        color: rgb(31, 115, 73);
        margin-right: 1vh;
    }

    .labels{
        font-weight:bold;
    }

    input[type=file]{
        display:none;
    }

    .text-info-table {
        font-weight: bold;
        font-size: 12px;
        color: var(--cinza-5);
    }

    .selection-excel-file-block label {
        width: 100%;
        background-color: var(--cinza-2);
        border: 1px var(--cinza-4) solid;
        padding-right: 12px;
        cursor: pointer;
    }

    .selection-excel-file-block label > i {
        padding: 7px 12px;
        text-align: center;
        background-color: #1f7349;
        color: #fff;
        font-size: 18px;
        margin-right: 7px;
    }

    .selection-excel-file-block input[type=file] {
        display: none;
    }
        
    #exel-Logo {
        font-size: 20px;
        color: white;
        background-color: rgb(31, 115, 73);
        padding: 10px;
    }

    .data-viewer {
        animation: select-method ease-in .3s;
    }

    .data-viewer-pagination > button {
        width: 50px;
    }

</style>